import styled from 'styled-components';
import Img from 'gatsby-image';

import Arrow from 'assets/icons/arrow-down.svg';

import { StyledContent as Content, StyledH2 } from 'components/Global.styles';
import { StyledTerms } from '../SinglePortfolioItem/SinglePortfolioItem.styles';

// --- HERO SECTION ---

export const HeroSection = styled.section`
  padding: 75px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
`;

export const PortfolioSection = styled.section`
  overflow: hidden;
  padding-top: 35px;
  padding-bottom: 35px;
  margin-top: -35px;
  .row {
    row-gap: 70px;
  }
`;

export const MainIllustration = styled.img`
  display: block;
  margin: 0 auto;
  width: 180px;
  height: auto;
`;

export const StyledContent = styled(Content)`
  text-align: center;
  max-width: 860px;
  margin-left: auto;
  margin-right: auto;
`;

export const ArrowDown = styled(Arrow)`
  position: absolute;
  bottom: 45px;
  left: 50%;
  transform: translateX(-50%);
`;
export const ArrowRight = styled(Arrow)`
  transform: translateX(15px) rotate(-90deg);
`;

// --- PORTFOLIO SECTION ---

export const ItemFinger = styled.div`
  transition: ${({ theme }) => theme.transition.default};
  margin-top: 25px;

  img {
    display: block;
    width: 85px;
    max-width: 100%;
    height: auto;
  }
`;

export const ItemTitle = styled(StyledH2)`
  letter-spacing: 0.03em;
  ${({ theme }) => theme.mq.mobile} {
    margin: 0;
  }
`;

export const ItemDescription = styled.div`
  margin-top: 30px;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
`;

export const ItemTerms = styled(StyledTerms)`
  border-top: 0;
  padding-top: 0;
  font-size: 1.2rem;
  line-height: 1.75;
  font-weight: 500;
  ${({ theme }) => theme.mq.mobile} {
    border-top: none;
    margin-top: 0;
  }

  li {
    flex: none;
    margin-right: 10px;
    position: relative;
    display: flex;
    align-items: center;
    min-width: 0;

    &:after {
      content: '';
      height: 60%;
      width: 1px;
      flex-basis: 1px;
      margin-left: 10px;
      background: currentColor;
    }

    &:last-child {
      margin-right: 0;

      &:after {
        content: none;
      }
    }
  }
`;

export const ItemBanner = styled.div`
  background: transparent;
  max-width: 100%;
  height: 240px;
  overflow: hidden;
  border-radius: 12px;
  border: 1px solid ${({ theme }) => theme.colors.text};
  ${({ theme }) => theme.mq.tabletMiddle} {
    height: auto;
  }
  ${({ theme }) => theme.mq.mobile} {
    flex: none;
    max-width: 100%;
  }
`;

export const StyledImg = styled(Img)`
  width: 100%;
  height: 100%;
  transition: transform 250ms ease;
  display: ${({ isMobile }) => (isMobile ? `none` : `block`)};
  ${({ theme }) => theme.mq.tabletMiddle} {
    display: ${({ isMobile }) => (!isMobile ? `none` : `block`)};
    border-radius: 10px;
    height: auto;
  }
`;

export const ItemContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
`;

export const PortfolioItem = styled.div`
  display: grid;
  grid-template-columns: 480px 1fr;
  text-decoration: none;
  color: ${({ theme }) => theme.colors.text};
  padding: 36px;
  gap: 36px;
  border-radius: 20px;
  box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.07);

  ${({ theme }) => theme.mq.tabletMiddle} {
    grid-template-columns: 1fr;
  }

  &:hover {
    text-decoration: none;
    color: inherit;
  }

  &:hover ${StyledImg} {
    transform: scale(1.15);
  }
`;

export const ContactWrapper = styled.div`
  position: relative;
  padding-top: 50px;

  ${StyledH2} {
    display: flex;
    align-items: flex-end;
    letter-spacing: 0.025em;
    margin-bottom: 25px;
  }

  p {
    font-size: 1.6rem;
    line-height: 1.25;
  }
`;

export const StyledContactLetter = styled.img`
  width: 160px;
  display: block;
  margin-left: 40px;
`;

export const StyledEmailLink = styled.a`
  display: block;
  font-size: 2rem;
  margin-top: 1.25em;
  text-decoration: underline;

  &,
  &:hover {
    color: inherit;
  }
`;
