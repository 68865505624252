import React, { useRef, useEffect } from 'react';
import { graphql, Link } from 'gatsby';
import { Container, Row, Col } from 'react-bootstrap';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import finger1 from 'assets/images/lapa1.png';
import finger2 from 'assets/images/lapa2.png';
import main_illustration from 'assets/images/main_illu.png';

import SEO from 'layout/SEO';
import { StyledH1, StyledH2, StyledContent as Content } from 'components/Global.styles';
import {
  HeroSection,
  MainIllustration,
  StyledContent,
  PortfolioItem,
  ItemBanner,
  ItemTitle,
  ItemTerms,
  ItemContent,
  ItemFinger,
  ContactWrapper,
  StyledImg,
  PortfolioSection,
  ItemDescription,
  StyledEmailLink,
} from './Homepage.styles';

if (typeof window !== `undefined`) {
  gsap.registerPlugin(ScrollTrigger);
  gsap.core.globals('ScrollTrigger', ScrollTrigger);
}

const Homepage = ({ data, location: { pathname } }) => {
  const {
    allWpSinglePortfolioItem: { nodes: portfolioData },
    allWpPortfolioType: { nodes: termsData },
    wpPage: pageData,
  } = data;

  const { contact, title: pageTitle, description: pageDescription } = pageData.acfHomepage;

  const posts = portfolioData.map((post) => {
    const terms = termsData.filter(({ databaseId: termId }) => {
      // console.log(post.portfolioTypes.nodes);
      return post.portfolioTypes?.nodes.find((t) => t.databaseId === termId);
    });
    return { ...post, portfolioType: terms };
  });

  const postsRef = useRef(new Array(posts.length));

  useEffect(() => {
    posts.forEach((post, index) => {
      const tl = gsap.timeline({
        defaults: { duration: 0.35 },
        scrollTrigger: {
          trigger: postsRef.current[index],
          start: 'top 80%',
          markers: false,
        },
      });
      tl.fromTo(postsRef.current[index], { autoAlpha: 0, x: 100 }, { autoAlpha: 1, x: 0 });
      tl.set(postsRef.current[index], { clearProps: 'all' });
    });
  }, []);

  return (
    <>
      <SEO title="Home" pathname={pathname} />
      <main>
        <HeroSection>
          <Container>
            <MainIllustration src={main_illustration} />
            <StyledH1 dangerouslySetInnerHTML={{ __html: pageTitle }} />
            <StyledContent dangerouslySetInnerHTML={{ __html: pageDescription }} />
          </Container>
        </HeroSection>
        <PortfolioSection>
          <Container>
            <Row>
              {posts.length &&
                posts.map((post, index) => {
                  const {
                    listTitle: portfoliolistTitle,
                    listThumbnail: portfoliolistThumbnail,
                    listThumbnailMobile: portfoliolistThumbnailMobile,
                    description: portfolioDescription,
                  } = post.acfSinglePortfolioItem;

                  let strippedPortfolioDescription = portfolioDescription;
                  if (typeof window !== `undefined`) {
                    const stripDiv = document.createElement('div');
                    stripDiv.innerHTML = portfolioDescription;
                    strippedPortfolioDescription = stripDiv.textContent;
                  }
                  return (
                    <Col key={post.slug} xs={12}>
                      <PortfolioItem
                        as={Link}
                        to={`/portfolio/${post.slug}`}
                        ref={(el) => {
                          postsRef.current[index] = el;
                        }}
                      >
                        <ItemBanner>
                          {portfoliolistThumbnail?.localFile && (
                            <StyledImg
                              fluid={portfoliolistThumbnail.localFile.childImageSharp.fluid}
                            />
                          )}
                          {portfoliolistThumbnailMobile?.localFile && (
                            <StyledImg
                              isMobile
                              fluid={portfoliolistThumbnailMobile.localFile.childImageSharp.fluid}
                            />
                          )}
                        </ItemBanner>
                        <ItemContent>
                          <ItemTitle
                            dangerouslySetInnerHTML={{
                              __html: portfoliolistTitle || post.title,
                            }}
                          />

                          {!!post.portfolioType.length && (
                            <ItemTerms hasSeparator>
                              {post.portfolioType.map((type) => (
                                <li key={type.databaseId}>{type.name}</li>
                              ))}
                            </ItemTerms>
                          )}
                          {strippedPortfolioDescription &&
                            strippedPortfolioDescription?.length > 0 && (
                              <ItemDescription
                                dangerouslySetInnerHTML={{ __html: strippedPortfolioDescription }}
                              />
                            )}
                          <ItemFinger>
                            <img src={index % 2 === 0 ? finger2 : finger1} alt="finger" />
                          </ItemFinger>
                        </ItemContent>
                      </PortfolioItem>
                    </Col>
                  );
                })}
              <Col xs={12} className="d-flex align-items-end">
                <ContactWrapper>
                  <StyledH2>{contact.title}</StyledH2>

                  <Content
                    dangerouslySetInnerHTML={{
                      __html: contact.description,
                    }}
                  />
                  {contact?.email && contact.email.length > 0 && (
                    <StyledEmailLink href={`mailto:${contact.email}`}>
                      {contact.email}
                    </StyledEmailLink>
                  )}
                </ContactWrapper>
              </Col>
            </Row>
          </Container>
        </PortfolioSection>
      </main>
    </>
  );
};

export default Homepage;

export const query = graphql`
  query homepageQuery($slug: String!) {
    wpPage(slug: { eq: $slug }) {
      databaseId
      title
      slug
      acfHomepage {
        title
        description
        contact {
          title
          description
          email
        }
      }
    }
    allWpSinglePortfolioItem(
      sort: { fields: date, order: DESC }
      filter: {
        databaseId: { ne: 212 }
        acfSinglePortfolioItem: { hideOnList: { eq: "off" } }
        contentType: { node: { name: { eq: "portfolio_item" } } }
        status: { eq: "publish" }
      }
    ) {
      nodes {
        slug
        title
        portfolioTypes {
          nodes {
            slug
            databaseId
          }
        }
        acfSinglePortfolioItem {
          listTitle
          description
          listThumbnail {
            localFile {
              childImageSharp {
                fluid(maxWidth: 1140, quality: 100) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
          listThumbnailMobile {
            localFile {
              childImageSharp {
                fluid(maxWidth: 730, quality: 100) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
    }
    allWpPortfolioType {
      nodes {
        slug
        name
        databaseId
      }
    }
  }
`;
