import styled from 'styled-components';
import Img from 'gatsby-image';
import { Col, Row } from 'react-bootstrap';

export const TopBackground = styled(Img)`
  border-radius: 15px;
  box-shadow: ${({ theme }) => theme.box_shadow(0, 0, 8, 0.15)};
  margin-bottom: 80px;
`;

export const StyledTerms = styled.ul`
  display: flex;
  flex-wrap: wrap;
  padding-top: 10px;
  border-top: 1px solid ${({ theme }) => theme.colors.text};
  ${({ theme }) => theme.mq.mobile} {
    margin-top: 24px;
  }

  li {
    flex: auto;
    min-width: 20%;
    ${({ theme }) => theme.mq.mobile} {
      min-width: 0;
      flex: 0 0 50%;
      max-width: 50%;
      margin: 5px 0;
    }
  }
`;

export const StyledRow = styled(Row)`
  margin-top: 40px;
  ${({ theme }) => theme.mq.mobile} {
    margin-top: 24px;
  }
`;
export const StyledCol = styled(Col)`
  display: flex;
  flex-direction: column;
  gap: 40px;
  ${({ theme }) => theme.mq.mobile} {
    gap: 24px;
  }
`;

export const Placeholder = styled.div`
  height: 70vh;
`;
